<template>
    <div class="container">
        <Detail v-model:detailId="detailId" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1500 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'typeDesc'">
                        {{ record?.cjWorkOrderInfo?.typeDesc }}
                    </template>
                    <template v-if="column.dataIndex === 'projectName'">
                        {{ record?.cjWorkOrderInfo?.projectName }}
                    </template>
                    <template v-if="column.dataIndex === 'projectCode'">
                        {{ record?.cjWorkOrderInfo?.projectCode }}
                    </template>
                    <template v-if="column.dataIndex === 'workReportUsers'">
                        {{ record?.cjWorkReportUsers?.length }}
                    </template>
                    <template v-if="column.dataIndex === 'date'">
                        {{ formateMoment(record?.productionStartTime, "YYYY-MM-DD") }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailId = record.id">详情</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button type="primary" @click="handleExport">导出报表</a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import { formateMoment, jsonToUrlParams, showMessage } from "@/utils/common";
import { apiCJWorkReportList } from "@/api";
import { baseURL } from "@/utils/config";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "项目名称",
        dataIndex: "projectName",
    },
    {
        title: "项目编码",
        dataIndex: "projectCode",
    },
    {
        title: "生产日期",
        dataIndex: "date",
    },
    {
        title: "工序",
        dataIndex: "cjWorkOrderStepName",
    },
    {
        title: "异常事项",
        dataIndex: "exceptionMatter",
    },
    {
        title: "生产人数",
        dataIndex: "workReportUsers",
    },
    {
        title: "异常时长(/h)",
        dataIndex: "exceptionWorkingHours",
    },
    {
        title: "异常总工时(/h)",
        dataIndex: "exceptionTotalWorkingHours",
    },
    {
        title: "备注",
        dataIndex: "exceptionDescription",
    },
    {
        title: "提交时间",
        dataIndex: "createdTime",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
    },
    setup() {
        const state = reactive({
            detailId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const handleExport = () => {
            let hasValue = false;
            if (state.params) {
                Object.values(state.params).find(item => {
                    if (typeof item == "string") item = item.trim();
                    if (item != undefined && item != "") {
                        hasValue = true;
                        return item;
                    }
                });
            }
            if (!hasValue) return showMessage("info", "请至少选择一个搜索条件");
            let url = `${baseURL}/web/mes-cj-work-report/export-have-exception-list`;
            url = url + jsonToUrlParams(state.params);
            window.open(url);
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiCJWorkReportList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    exception: true,
                },
                ...params,
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const handleOpenDetail = id => {
            state.detailId = id;
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            handleOpenDetail,
            getTabelData,
            formateMoment,
            baseURL,
            handleExport,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
